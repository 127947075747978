.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 340px;
  height: 100vh;
  text-align: center;
  background: $JSblack;
  color: $JSyellow;
  transform: translate(-100%);
  transition: 0.5s;
  
  &.open {
    transform: translate(0);
  }
}