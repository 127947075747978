@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Coda+Caption:wght@800&display=swap);
@charset "UTF-8";
.nav {
  width: 100%;
  background-color: #f0db4f;
  display: flex;
  justify-content: center;
  z-index: 999; }
  .nav .nav-content {
    width: 900px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    color: #323330;
    font-family: 'Quicksand', sans-serif;
    padding: 15px; }
    .nav .nav-content .logo {
      margin-bottom: 10px;
      padding: 5px;
      font-size: 4rem;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none; }
    .nav .nav-content ul {
      list-style: none;
      margin-bottom: 10px;
      display: flex; }
      .nav .nav-content ul li a {
        color: #323330;
        -webkit-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
        text-decoration: none;
        font-size: 2rem;
        padding: 7px;
        border-radius: 5px;
        transition: 0.3s;
        margin: 0 5px;
        position: relative; }
        .nav .nav-content ul li a.active {
          position: relative;
          color: #f0db4f;
          z-index: 0; }
          .nav .nav-content ul li a.active::before {
            content: '';
            display: block;
            position: absolute;
            background: #323330;
            z-index: -1;
            -webkit-animation: strike 0.1s linear forwards;
                    animation: strike 0.1s linear forwards; }

@-webkit-keyframes strike {
  0% {
    width: 0%;
    height: 100%; }
  100% {
    width: 100%;
    height: 100%; } }

@keyframes strike {
  0% {
    width: 0%;
    height: 100%; }
  100% {
    width: 100%;
    height: 100%; } }
        .nav .nav-content ul li a::before {
          content: '';
          display: flex;
          position: absolute;
          bottom: 0;
          right: 0;
          width: 0;
          height: 5px;
          background-color: #323330;
          transition: 0.3s; }
        .nav .nav-content ul li a:hover::before {
          width: 100%; }

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 340px;
  height: 100vh;
  text-align: center;
  background: #323330;
  color: #f0db4f;
  transform: translate(-100%);
  transition: 0.5s; }
  .sidebar.open {
    transform: translate(0); }

.home {
  width: 100%;
  height: calc(100vh - 180px);
  display: flex;
  align-items: center;
  justify-content: center; }

.quote {
  font-size: 2rem;
  font-family: 'Quicksand', sans-serif;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  z-index: 0; }
  .quote p {
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: italic;
    text-align: center;
    padding: 20px 50px;
    max-width: 750px;
    min-height: 150px;
    margin: 20px;
    border: 5px solid #f0db4f; }
  .quote::before, .quote::after {
    font-size: 5rem;
    position: absolute;
    display: block;
    background: #323330;
    padding: 10px;
    font-family: 'Coda Caption', sans-serif; }
  .quote::before {
    content: '“';
    line-height: 1rem;
    top: 35px;
    left: 5px; }
  .quote::after {
    content: '”';
    line-height: 4rem;
    bottom: -5px;
    right: 3px; }

.bio {
  width: 100%;
  height: calc(100vh - 180px);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column; }
  .bio div {
    display: flex;
    align-items: center;
    flex-direction: column; }
    .bio div h1 {
      font-size: 3rem;
      font-family: 'QuickSand', sans-serif;
      padding: 10px; }
    .bio div p {
      width: 850px;
      text-align: center;
      font-family: 'QuickSand', sans-serif;
      font-size: 1.8rem; }

* {
  margin: 0;
  padding: 0; }

html,
body {
  width: 100%;
  height: 100vh;
  background-color: #323330;
  color: #f0db4f;
  overflow-x: hidden; }

