.bio {
	width: 100%;
	height: calc(100vh - 180px);
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;

	div {
		display: flex;
		align-items: center;
		flex-direction: column;

		h1 {
			font: {
				size: 3rem;
				family: 'QuickSand', sans-serif;
			}

			padding: 10px;
		}

		p {
			width: 850px;
			text-align: center;

			font: {
				family: 'QuickSand', sans-serif;
				size: 1.8rem;
			}
		}
	}
}