.nav {
	width: 100%;
	background-color: $JSyellow;
	display: flex;
	justify-content: center;
	z-index: 999;

	.nav-content {
		width: 900px;
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		color: $JSblack;
		font-family: 'Quicksand', sans-serif;
		padding: 15px;

		.logo {
			margin-bottom: 10px;
			padding: 5px;
			font-size: 4rem;
			user-select: none;
		}

		ul {
			list-style: none;
			margin-bottom: 10px;
			display: flex;

			li {
				a {
					color: $JSblack;
					user-select: none;
					text-decoration: none;
					font-size: 2rem;
					padding: 7px;
					border-radius: 5px;
					transition: 0.3s;
					margin: 0 5px;
					position: relative;

					&.active {
						position: relative;
						color: $JSyellow;
						z-index: 0;

						&::before {
							content: '';
							display: block;
							position: absolute;
							background: $JSblack;
							z-index: -1;
							animation: strike 0.1s linear forwards;

							@keyframes strike {
								0% {
									width: 0%;
									height: 100%;
								}

								100% {
									width: 100%;
									height: 100%;
								}
							}
						}
					}

					&::before {
						content: '';
						display: flex;
						position: absolute;
						bottom: 0;
						right: 0;
						width: 0;
						height: 5px;
						background-color: $JSblack;
						transition: 0.3s;
					}

					&:hover {
						&::before {
							width: 100%;
						}
					}
				}
			}
		}
	}
}