@import '../assets/variables';
@import '../assets/fonts';
@import './Navbar';
@import './Sidebar';
@import './Home';
@import './QuoteBox';
@import './Bio';

* {
	margin: 0;
	padding: 0;
}

html,
body {
	width: 100%;
	height: 100vh;
	background-color: $JSblack;
	color: $JSyellow;
	overflow-x: hidden;
}