.quote {
	font-size: 2rem;
	font-family: 'Quicksand', sans-serif;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 20px;
	z-index: 0;

	p {
		display: flex;
		align-items: center;
		justify-content: center;
		font-style: italic;
		text-align: center;
		padding: 20px 50px;
		max-width: 750px;
		min-height: 150px;
		margin: 20px;
		border: 5px solid $JSyellow;
	}

	&::before,
	&::after {
		font-size: 5rem;
		position: absolute;
		display: block;
		background: $JSblack;
		padding: 10px;
		font-family: 'Coda Caption', sans-serif;
	}

	&::before {
		content: '“';
		line-height: 1rem;
		top: 35px;
		left: 5px;
	}

	&::after {
		content: '”';
		line-height: 4rem;
		bottom: -5px;
		right: 3px;
	}
}